import '../scss/main.scss';

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import './libs/Hyphenopoly_Loader.js';
import Utils from './modules/Utils';
import CookieHintHelper from './modules/CookieHintHelper.js';
import CustNoRequirement from './modules/CustNoRequirement.js';

window.$ = jQuery;
window.jQuery = jQuery;

if (document.readyState != 'loading') {
    onDomReady();
} else {
    document.addEventListener('DOMContentLoaded', onDomReady);
}

function onDomReady() {

    new CookieHintHelper();
    new CustNoRequirement();

    var Hyphenopoly = {
        require: {
            "de": "Silbentrennungsalgorithmus"
        }
    };

    const htmlClassList = document.documentElement.classList;
    const utils = new Utils();
    
    if (utils.isIE()) htmlClassList.add('ie', 'ie' + utils.getInternetExplorerVersion());
    if (utils.isEdge()) htmlClassList.add('edge');
    if (utils.isGC()) htmlClassList.add('gc', 'gc' + utils.getGoogleChromeVersion());
    if (utils.isiOS()) htmlClassList.add('ios', 'ios' + utils.getiOSVersion());

    const seminarslider = document.querySelectorAll('.seminarSliderWrapper');
    if (seminarslider.length > 0) {
        Promise.all([
            import('./modules/SliderSeminar'),
            import('swiper'),
            import('swiper/scss'),
            import('swiper/scss/navigation'),
            import('./modules/SliderNavigationHelper')
        ]).then((values) => {
            const SliderSeminar = values[0].default;
            SliderSeminar.inject(
                values[1].Swiper,
                values[1].Navigation,
                values[4].default
            );

            seminarslider.forEach((item) => new SliderSeminar(item));
        });
    }

    $('.powermail_fieldwrap_addresstype input[type="radio"]').change(function() {
        var $elm = $('.powermail_fieldwrap_company');
        var $field = $('#powermail_field_company');

        if($('#powermail_field_addresstype_1').is(':checked')) {
            $elm.removeClass('hidden');
        }
        else {
            $elm.addClass('hidden');
            $field.val('');
        }
    });
}