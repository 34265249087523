var localStorageItemName = 'weka-'+document.documentElement.getAttribute('lang')+'-cookieNoteShown';
const cookieName = 'weka_' + document.documentElement.getAttribute('lang') + '_cookieNoteShown';

export default class CookieHintHelper {
    constructor() {
        this.fetchDomElements();
        this.registerEventListeners();
        this.initiateCookie();
    }

    fetchDomElements() {
        this.domElements = {
            cookieHintContainer: document.getElementById('cookieHintContainer'),
            cookieHintToggle: document.getElementById('cookieHintToggle'),
        };
    }

    registerEventListeners() {
        this.domElements.cookieHintToggle.addEventListener('click', (e) => this.onCookieHintToggleBtnClick(e));
    }

    onCookieHintToggleBtnClick(e) {
        e.preventDefault();

        // window.localStorage.setItem(localStorageItemName, 'true');
        this.setCookie(cookieName, 'true');
        document.body.classList.remove('cookieHintOpen');
    }

    initiateCookie() {
        // if (window.localStorage.getItem(localStorageItemName)) {
        if (this.getCookie(cookieName)) {
            return;
        }

        if (window.location.search.indexOf('no_cookies=1') != -1) {
            return;
        }

        if (document.querySelectorAll('input[name="no_cookies"][value="1"]').length > 0) {
            return;
        }

        document.body.classList.add('cookieHintOpen');
    }

    setCookie(name, value) {
        document.cookie = name + '=' + (value || '') + '; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    }

    getCookie(name) {
        let nameEQ = name + '=';
        let parts = document.cookie.split(';');
        
        for (let i = 0; i < parts.length; i++) {
            let c = parts[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }
}
